<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :isCheckInputBox="false"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="onReset"
        @onSearch="onSearch">
    </list-template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      // 表格搜索配置
      searchConfig: [{
        prop: 'school_id',
        tag: 'select',
        placeholder: '请选择校区',
        options: [],
        label: 'name',
        value: 'id',
        change: (val, search) => {
          this.search = search
          this.search.grade_id = ''
          this.search.class_id = ''
          this.$_axios2.get('api/evaluation/result/get-range?school_id=' + val).then(res => {
            if (res.data.status === 0) {
              this.searchConfig[1].options = res.data.data.grade;
              this.searchConfig[2].options = res.data.data.class;
            }
          })
        }
      }, {
        prop: 'grade_id',
        tag: 'select',
        placeholder: '请选择年级',
        options: [],
        label: 'name',
        value: 'id',
        change: (val,search) => {
          this.search = search
          this.search.class_id = ''
          this.$_axios2.get('api/evaluation/result/get-range?grade_id=' + val + '&school_id=' + this.search.school_id).then(res => {
            if (res.data.status === 0) {
              this.searchConfig[2].options = res.data.data.class;
            }
          })
        }
      }, {
        prop: 'class_id',
        tag: 'select',
        placeholder: '请选择行政班',
        options: [],
        label: 'name',
        value: 'id',
      }, {
        prop: 'student_name',
        placeholder: '输入测评名称,学生关键字'
      },],
      // 表格配置
      tableConfig: [{
        prop: 'evaluation',
        label: '测评名称',
      }, {
        prop: 'student_name',
        label: '学生姓名',
      }, {
        prop: 'student_no',
        label: '学号',
        showOverflowTooltip: true
      }, {
        prop: 'class',
        label: '行政班'
      }, {
        prop: 'grade',
        label: '年级'
      }, {
        prop: 'school',
        label: '校区'
      }, {
        label: '操作',
        handle: true,
        width: '120',
        buttons: [{ type:"view", text:"查看" }]
      }],
      search: null,
      page: 1,
      total: 0,
      loading: true,
      // 表格中的数据
      tableData: []
    }
  },
  activated() {
    this.getData()
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.commonInterface()
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let params = { ...this.search }
      params.page = this.page
      this.loading = true;
      this.$_axios2('api/evaluation/psych/get-list', { params }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false);
    },

    // 搜索按钮被点击
    onSearch(val) {
      this.search = val
      setTimeout(() => {
        this.getData()
      }, 10)
    },

    onReset() {
      this.search = null
      this.page = 1
      this.commonInterface()
    },

    // 表格后面操作被点击
    tableHandle(row, text, index) {
      this.$router.push('./look?id=' + row.id)
    },

    commonInterface() {
      this.$_axios2.get('api/evaluation/result/get-range').then(res => {
        if (res.data.status === 0) {
          this.searchConfig[0].options = res.data.data.school;
          this.searchConfig[1].options = res.data.data.grade;
          this.searchConfig[2].options = res.data.data.class;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.teacher {
  min-width: 1250rem;
}

.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }


}

.title {
  font-size: 20rem;
  font-weight: bold;
  margin-bottom: 20rem;
  color: #333333;
}

.title_item {
  text-align: right;
}
</style>
